import { SocialSignInMethods } from "./enum/socialSignInMethods.enum";
import { isDev } from "./util/helper";

export const config = {
    adminUserId: 1,
    firebaseStorageUrl: isDev() ? 'odocert-dev.appspot.com' : 'odocert-prod.appspot.com',
    serverUrl: isDev() ? 'http://localhost:3600/be' : `https://be.odocert.com/be`,
    socialSignInMethod: SocialSignInMethods.PopUp,
    mobileWidth: 900,
    pageSize: 10,
    showIntro: false,
    recordImageCount: 2,
    vehicleSaleImageCount: 4,
    sparePartsImageCount: 3,
    vehicleMaintananceImageCount: 1,
    cacheKeyRetentionDays: 30,
    popupShowDuration: 6000,
    pwaNotificationDelay: 10000,

    inputTextLimit: 40,
    minTextLength: 5,
    multilineTextLimit: 200,
    
    maxDimentionsForImageUpload: 700,

    maxLevelsOfAreaInputs: 3,

    welcomeTimer: 1700,
    introBeginTimer: 1500,
    imageCacheSeconds: 3600*24*30*2,
    yMin: 4000,
    yMax: -1,
    inputHeight: '40px',
    inputMinWidth: '20px',
    vehicleViewHeight: 230,
    chartFuelRecordsLimit: 100,
    fuelRecordsPageSize: 40,
    chartHeight: '20vh',
    mapApiKey: 'AIzaSyDrThTfCA3rM21AzHHPX0ppIgVr0JCqJdA',
    defaultVehicleImageUrl: 'https://firebasestorage.googleapis.com/v0/b/odocert-prod.appspot.com/o/config%2Fno-image.png?alt=media',
    tempResourceId: -5555
}

export const firebaseStr = '226137200215208178204224137161137168176225200186224169208187216173169171191217155173184148225216160178177172221215181219183209148218223212210212188180137147137200220219207171214212200208213137161137214203214202204217219148215217214203149205208217204201200218204200215215149202214212137147137215217214209204202219176203137161137214203214202204217219148215217214203137147137218219214217200206204169220202210204219137161137214203214202204217219148215217214203149200215215218215214219149202214212137147137212204218218200206208213206186204213203204217176203137161137159154156156160158158153154152152159137147137200215215176203137161137152161159154156156160158158153154152152159161222204201161160153155158152156155155202159205158200158204201202205156200205205137147137212204200218220217204212204213219176203137161137174148180154193156192174160187152158137228';

export const firebaseConfigDev = {
    apiKey: "AIzaSyAL-2H0eTeV-DM_EOk2UnMUKG_b_tY_ZGw",
    authDomain: "odocert-dev.firebaseapp.com",
    projectId: "odocert-dev",
    storageBucket: "odocert-dev.appspot.com",
    messagingSenderId: "450537234721",
    appId: "1:450537234721:web:ad5d6a7735ff0f9aa0de60",
    measurementId: "G-LZ3E56RJDQ"
};