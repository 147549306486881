import { useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { useLogoutMutation } from "../../state/api/user.api";
import { toggleSidePanel } from "../../state/slice/appSlice";
import { useAppDispatch, useAppSelector } from "../../state/store";
import { TopBar } from "./topbar";
import { useNavigationMenu } from "../../hooks/useNavigationMenu";
import { setLocalStorage } from "../../util/localData";
import { LocalStorageKeys } from "../../enum/localStorageKeys.enum";

export const TopBarContainer = () => {
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const { user } = useUser();
  const isLoginVisible = useAppSelector((state) => state.app.login.isVisible);
  const showSidePanel = useAppSelector((state) => state.app.showSidePanel);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key") ?? "";
  const {pages, selectedPage} = useNavigationMenu();

  const handleNavigate = (path: string) => {
    setLocalStorage(LocalStorageKeys.lastNavigation, path);
    navigate(path);
  };

  const handleLogout = async () => {
    await logout({user});
    navigate(`/login`);
    window.location.reload();
  };

  const handleLogin = async () => {
    navigate(`/login`);
  };

  const handleToggleSidePanel = (visibility: boolean) => {
    dispatch(
      toggleSidePanel(visibility)
    );
  }

  return (
    <TopBar
      user={user}
      selectedPage={selectedPage}
      pages={pages}
      onMenuClick={handleNavigate}
      onLogout={handleLogout}
      onLogin={handleLogin}
      searchKey={key}
      isLoginVisible={isLoginVisible}
      onNavigate={handleNavigate}
      showSidePanel={showSidePanel}
      onToggleSidePanel={handleToggleSidePanel}
    />
  );
};
